import React from "react"

import { graphql, Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import BlogProfile from "../../components/blog-profile"

const IndexPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const Posts = edges
    .filter(
      edge => !!edge.node.frontmatter.date && !edge.node.frontmatter.preview
    ) // You can filter your posts based on some criteria
    .map(edge => {
      const post = edge.node.frontmatter
      return (
        <article key={edge.node.id} className="py-8 border-b md:flex" id="post">
          <div id="meta" className="w-80 ">
            <time className="mb-4 uppercase tracking-wide block">
              {post.date}
            </time>
            <BlogProfile post={post} />
          </div>
          <div id="post" className="mb-2 justify-between">
            <Link to={"/blog" + post.slug}>
              <h2 className="text-2xl font-bold mb-3">{post.title}</h2>
            </Link>
            <p className="text-lg mb-2">{edge.node.excerpt}</p>
            <Link
              to={"/blog" + post.slug}
              className="text-primary tracking-wide uppercase text-sm font-bold"
            >
              Read more →
            </Link>
          </div>
        </article>
      )
    })

  return (
    <Layout>
      <SEO title="Blog" />
      <div className="container mx-auto pt-6 pb-48  px-6" id="blog-list">
        <div className="text-center mb-8">
          <h1 className="my-4 text-4xl font-medium leading-snug tracking-wider">
            Latest
          </h1>
          The latest news and stories from our blog
        </div>
        {Posts}
      </div>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
            author
            authorTitle
            preview
            profile {
              childImageSharp {
                gatsbyImageData(width: 200)
              }
            }
          }
        }
      }
    }
  }
`
